import { useState, useEffect, useCallback } from "react";
import { Button, Card, Avatar, Alert, Modal } from "antd";
import { useSelector } from "react-redux";

import { get } from "lodash-es";
import { decodeString } from "../../util/misc";
import EditStudentDrawer from "../StudentsCard/EditStudentDrawer";
import AdminEditStudentDrawer from "../../routes/admin/adminStudents/AdminEditStudentDrawer";
import TeacherAssistantEditStudentDrawer from "../../routes/teacherAssistant/teacherAssistantStudents/TeacherAssistantEditStudentDrawer";
import { checkIfAccountHasDiscrepantStudents } from "../../containers/App/utilFunctions";
import * as XLSX from "xlsx";

const setAvatar = (inputURL) => {
    if (
      !inputURL ||
      inputURL === "https://i.imgur.com/XqL3xLp.png" ||
      inputURL ===
        "https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png"
    ) {
      return "/assets/images/avatar-outline/avatar2.jpeg";
    }
    return decodeString(inputURL);
};

const StudentDataVerification = ({isVisible, onClose}) => {

	const { db }                                                                    = useSelector(({ auth }) => auth);
    const [mismatchedStudentCards, setMismatchedStudentCards]                       = useState([]);

    const [verifyStudentUID, setVerifyStudentUID]                                   = useState(undefined);
    const [showStudentEditDrawerForTeachers, setShowStudentEditDrawerForTeachers]   = useState(false);
    const [showStudentEditDrawerForAdmins, setShowStudentEditDrawerForAdmins]       = useState(false);
    const [showStudentEditDrawerForMods, setShowStudentEditDrawerForMods]           = useState(false);

    const [excelData, setExcelData]                                                 = useState([]); 

    useEffect(()=>{

        if (!db) return () => { };
        if (get(db, ['PublicInfo', 'UserProffession'], 'Unknown') === 'Student') return () => {};

        //handle mismatched students for teacher accounts
        let {discrepantUIDArr, suidToDataMapper, discrepantUIDToKeyMapper} = checkIfAccountHasDiscrepantStudents(db);

        console.log('Discrepant UID Key Mapper: ', discrepantUIDToKeyMapper);

        let outputJSX                                                      = [];
        let excelData__                                                    = [];       

        for (let oneDiscrepantUID of discrepantUIDArr) {
            let studentData = get(suidToDataMapper, [oneDiscrepantUID], {});
            outputJSX.push(
                <Card
                className="gx-mod-batch-card gx-mb-2"
                style={{
                    border: 'none',

                }}
            >
                <div className="gx-d-flex gx-align-items-center" style={{justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row'}}>
                        <Avatar size="large" src={setAvatar( studentData.avatarURL )} alt="avatar" />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                            <span className='gx-ml-2'>{decodeString(studentData.Name)}</span>
                            <span className='gx-ml-2'>{decodeString(studentData.customID)}</span>
                        </div>
                    </div>

                    <Button
                        className="gx-mb-0 gx-btn-actionBlue"
                        disabled={false}
                        onClick={() => { 
                            console.log('Verify Student UID: ', oneDiscrepantUID);
                            setVerifyStudentUID(oneDiscrepantUID);
                            if (get(db, ['PublicInfo', 'UserProffession'], 'Unknown') === 'Teacher'){
                                console.log('Showing Teacher Student Edit Drawer..');
                                setShowStudentEditDrawerForTeachers(true);
                            }
                            else if (get(db, ['PublicInfo', 'UserProffession'], 'Unknown') === 'Admin'){
                                console.log('Showing Admin Student Edit Drawer..');
                                setShowStudentEditDrawerForAdmins(true);
                            }
                            else if (get(db, ['PublicInfo', 'UserProffession'], 'Unknown') === 'TeacherAssistant'){
                                console.log('Showing Moderator Student Edit Drawer..');
                                setShowStudentEditDrawerForMods(true);
                            }
                        }}
                    >Verify</Button>
                </div>
            </Card>);

            if (discrepantUIDToKeyMapper){
                excelData__.push([decodeString(studentData.Name), decodeString(studentData.customID), `Discrepant Data: ${discrepantUIDToKeyMapper[oneDiscrepantUID]}`]);
            }
        }

        setMismatchedStudentCards(outputJSX);
        setExcelData(excelData__);

    }, [db]);

    const downloadExcelOfUnverifiedStudents = useCallback(() => {
        let createXLSLFormatObj             = [];
            
        /* XLS Head Columns */
        let xlsHeader                       = ["Student Name", "ID", "Discrepancy Details"];

        createXLSLFormatObj.push(xlsHeader);

        /* XLS Rows Data */
        let xlsRows                         = excelData ;

        /* File Name */
        let filename                        = `Discrepant-Data-Excel-${get(db, ['PublicInfo', 'UserName'], 'Unknown')}-${(new Date()).getTime()}.xlsx`;

        /* Sheet Name */
        let ws_name                         = `Verify`;

        createXLSLFormatObj.push(...xlsRows);

        let wb                              = XLSX.utils.book_new()
        let ws                              = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);

            
        /* Write workbook and Download */
        XLSX.writeFile(wb, filename);

    }, [db, excelData]);

	return (
		<>
            <Modal
                title           ="Student Data Verification"
                style           ={{ top: '2.5%', right: '34%', zIndex: 100 }}
                width           ={440}
                visible         ={isVisible}
                onCancel        ={onClose}
                destroyOnClose  ={true}
                footer          ={[
                    window.location.href.includes('localhost') || window.location.href.includes('react-preview') ? <Button onClick={downloadExcelOfUnverifiedStudents}>Download Excel</Button> : null
                ]}
                cancelText      = {'Close'}
                mask            = {false}
                maskClosable    = {false}
                bodyStyle       ={{maxHeight: '80vh', overflowY: 'scroll'}}
                
            >
                <div id="studentDataVerificationModal"></div>
                <Alert closable={true} message={'These are the accounts which our AI has detected may have incorrect / incomplete data.'} type="info" showIcon/>
                {mismatchedStudentCards}
            </Modal>

            <EditStudentDrawer 
                title                   =   "Verify Student Info" 
                formSubmitText          =   "Verify & Save"
                visible                 =   {showStudentEditDrawerForTeachers} 
                size                    =   {'large'} 
                studentUID              =   {verifyStudentUID}
                onClose                 =   {()=>{
                    setShowStudentEditDrawerForTeachers(false);
                }} 
                zIndex                  =   {2001}
                hideAddedBatchInfo      =   {true}
            />

            <AdminEditStudentDrawer 
                title                   =   "Verify Student Info" 
                formSubmitText          =   "Verify & Save"
                visible                 =   {showStudentEditDrawerForAdmins} 
                size                    =   {'large'} 
                studentKey              =   {verifyStudentUID}
                onClose                 =   {()=>{
                    setShowStudentEditDrawerForAdmins(false);
                }} 
                zIndex                  =   {1005}
                hideAddedBatchInfo      =   {true}
            />

            <TeacherAssistantEditStudentDrawer 
                title                   =   "Verify Student Info" 
                formSubmitText          =   "Verify & Save"
                visible                 =   {showStudentEditDrawerForMods} 
                size                    =   {'large'} 
                studentKey              =   {verifyStudentUID}
                onClose                 =   {()=>{
                    setShowStudentEditDrawerForMods(false);
                }} 
                zIndex                  =   {1005}
                hideAddedBatchInfo      =   {true}
            />
		</>
	);
};

export default StudentDataVerification;
