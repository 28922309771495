import mixpanel from 'mixpanel-browser';
import firebase from 'firebase';
import EdutechCloudServer from '../EdutechCloudServer';
import axios from 'axios'
import {get} from "lodash-es";
import { database } from '../firebase/firebase';
import { useSelector } from 'react-redux';
import CloudDatabase from './CloudDatabase';
import { encodeString } from '../util/misc';

let analytics = firebase.analytics();

class LogsPanel {


    setUserData({uid, proff, email, name}){
        mixpanel.register({uid, proff, 'Email': email, name, email, 'Name': name});
        mixpanel.identify(uid);
        mixpanel.people.set({uid, proff, 'Email': email, email, name, 'Name': name});

        analytics.setUserId(uid);
        analytics.setUserProperties({uid: uid});
        analytics.setUserProperties({proff: proff});
        analytics.setUserProperties({email: email});
        analytics.setUserProperties({name: name});
    }

    recordLogs({userUID, eventName, eventProperties}){
        const timeStamp    =   new Date().getTime();

        if((eventName === undefined || eventName === null) && (eventProperties === undefined || eventProperties === null)){
            return false;
        }

        const dbAddress = `Logs/${userUID}/`
        const updateObj = {
            timeStamp: timeStamp,
            eventName: eventName,
            eventProperties: eventProperties,
            userUID: userUID,
            dbAddress: encodeString(dbAddress)
        }
        // console.log(updateObj)

        try{
            CloudDatabase.push(dbAddress, updateObj);
            console.log("log save")
        }
        catch(e){
            console.log(e);
        }
    }

    recordProxy({eventName, eventProperties, distinct_id, recordEvenInLocalhost=false}){
        
        if ((window.location.origin).includes('localhost') && recordEvenInLocalhost !== true){
            return false;
        }

        analytics.logEvent(eventName, eventProperties);

        axios.get('https://geolocation-db.com/json/')
        .then(res=>{
            let ipAddress_USER              = get(res, ['data', 'IPv4'], '127.0.0.1');
            EdutechCloudServer.postRequest('mixpanel-cloud-record', {
                event: eventProperties, eventName, ip: ipAddress_USER, distinct_id 
            }, (response) => {
                // console.log(response);
            });
        })
        .catch(e=>{
            console.log(e);
            let ipAddress_USER              = '127.0.0.1';
            EdutechCloudServer.postRequest('mixpanel-cloud-record', {
                event: eventProperties, eventName, ip: ipAddress_USER, distinct_id 
            }, (response) => {
                // console.log(response);
            });
        })
    }
}


export default new LogsPanel()